import type { StoreTypes as T } from '.'
import useAuthStore from '~/features/auth/store'
import { printStrategies } from '~/features/printer/store/printer-class.client'
import type { PrinterType } from '~/features/printer/store/types'

const API_ROUTE_2 = '/warehouse'

/**
 * Create collection label
 * @deprecated Use `Factories version of this function`
 */
export async function createStoreCollectionLabel(
  this: T,
  payload: {
    OrderNumber: string
    VerifiedDate: string
    Customer: string
    Qty: number
    Type: string
    Packages: number
  },
  print = false
): Promise<{
  contents: string
  file_name: PrinterType['file_name']
  file_type: PrinterType['file_type']
}> {
  this.loading.create_label = true

  const defaultPrinter = useAuthStore().printer.printerConfigs
  const collection_labels = defaultPrinter.find(
    ({ printer_name }) => printer_name === 'collection_labels'
  )

  return await useWretch('FrAPI')
    .url(API_ROUTE_2 + '/labels/storeCollectionLabel?wrapDataUri=true')
    .json({
      pageSize: collection_labels?.paper_media,
      ...payload
    })
    .post()
    .res()
    .then((response) => {
      const res = {
        contents: response.data,
        file_name: payload.OrderNumber + '_collection_label' + '.' + 'pdf',
        file_type: 3
      } as {
        contents: string
        file_name: PrinterType['file_name']
        file_type: PrinterType['file_type']
      }
      this.label = res

      if (print && import.meta.client)
        new printStrategies().collectionLabels(res)

      return res
    })
    .catch((err) => err)
    .finally(() => (this.loading.create_label = false))
}
