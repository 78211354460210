import { initialState_warehouse_labels } from './state'
import * as actions from './actions'

export const useLabelsStore = defineStore('warehouse-labels', {
  state: () => initialState_warehouse_labels,
  actions: {
    ...actions
  }
})

export type StoreTypes = ReturnType<typeof useLabelsStore>
